import observeDOM from "./observeDOM";
import { isImgLoadingAttrSupported } from "./isImgLoadingAttrSupported";
export default function makeImagesLazy(el) {
  if (!isImgLoadingAttrSupported) {
    observeDOM(el, function () {
      let lazyloadImages;
      // lazyloadImages = document.querySelectorAll("img[loading=lazy]");
      // console.log(lazyloadImages);
      if ("IntersectionObserver" in window) {
        lazyloadImages = document.querySelectorAll("img[loading=lazy]");
        let imageObserver = new IntersectionObserver(function (entries, observer) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              let image = entry.target;
              image.src = image.dataset.src;
              image.removeAttribute("loading");
              imageObserver.unobserve(image);
            }
          });
        });

        lazyloadImages.forEach(function (image) {
          imageObserver.observe(image);
        });
      } else {
        let lazyloadThrottleTimeout;
        lazyloadImages = document.querySelectorAll("img[loading=lazy]");

        function lazyload() {
          if (lazyloadThrottleTimeout) {
            clearTimeout(lazyloadThrottleTimeout);
          }

          lazyloadThrottleTimeout = setTimeout(function () {
            let scrollTop = window.pageYOffset;
            lazyloadImages.forEach(function (img) {
              if (img.offsetTop < (window.innerHeight + scrollTop)) {
                img.src = img.dataset.src;
                img.removeAttribute("loading");
              }
            });
            if (lazyloadImages.length == 0) {
              document.removeEventListener("scroll", lazyload);
              window.removeEventListener("resize", lazyload);
              window.removeEventListener("orientationChange", lazyload);
            }
          }, 20);
        }

        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
      }
    })
  }
}