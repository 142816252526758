import "./translations";
import React from "react";
import PropTypes from "prop-types";
// Material UI
import { withStyles } from "@material-ui/core/styles";
import DyAvatar from 'components/DyAvatar';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// App
import timeReadableOf from "utils/timeReadableOf";
import truncate from "utils/truncate";
import ErrorSvg from "images/error";
import styles from "./styles";

function DyTestimonial(props) {
  const { classes, testimonial, className, handleShowingDetails } = props;
  const avatarUrl = (testimonial.coverPhoto && testimonial.coverPhoto.thumbUrl) || ErrorSvg;
  const handleOpen = (() => handleShowingDetails({ open: true, ...testimonial }));
  return (
    <div className={className}>
      <Card className={classes.wrapper} elevation={2}>
        <CardHeader
          avatar={
            <DyAvatar lazyLoad alt={testimonial.name} src={avatarUrl} />
          }
          title={testimonial.name}
          subheader={timeReadableOf(testimonial.receivedDate)}
        />
        <CardContent>
          <Typography
            className={classes.content}
            component="p"
            variant="body1"
          >
            {truncate(testimonial.content, 99)}
          </Typography>
        </CardContent>
        <CardActions className={classes.contentWrapper}>
          <Button color="primary" onClick={handleOpen}>
            {I18n.t('components.testimonial.read_more')}
          </Button>
        </CardActions>
      </Card>
    </div>
    );
}

DyTestimonial.propTypes = {
  classes: PropTypes.object.isRequired,
  testimonial: PropTypes.object.isRequired
};

DyTestimonial.defaultProps = {
  testimonial: undefined
};

export default withStyles(styles)(DyTestimonial);
