import "./translations";
import React from "react";
// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// App
import { FB_GROUP_URL } from "commons/initializer";
import styles from "./styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import DyContactList from "components/DyContactList";

function FooterLink(props) {
  const classes = styles();
  const { children, ...ptProps } = props;
  return (
    <Typography 
      component="a"
      variant="caption"
      gutterBottom
      className={classes.footerLink}
      {...ptProps}
    >
      {children}
      <br/>
    </Typography>
  );
}

function FooterHeader(props) {
  return (
    <Typography
      component="p"
      variant="h6"
      gutterBottom
      {...props}
    />
  );
}

function AboutParagraph(props) {
  const classes = styles();
  return (
    <Typography 
      variant="caption"
      component="p"
      className={classes.about}
      {...props}
    />
  );
}

function DyFooter(props) {
  const classes = styles();
  return (
    <Paper component="footer" className={classes.footerCont} elevation={2}>
      <Divider />
      <Container maxWidth="xl">
        <Grid container className={classes.footer}>
          <Grid item xs={12} md={6}>
            <FooterHeader>{I18n.t("components.footer.about.header")}</FooterHeader>
            <AboutParagraph>
              {I18n.t("components.footer.about.p1")}
            </AboutParagraph>
            <AboutParagraph>
              {I18n.t("components.footer.about.p2")}
            </AboutParagraph>
            <AboutParagraph>
              {I18n.t("components.footer.about.p3")}
            </AboutParagraph>
          </Grid>
          <Grid item xs={12} md={3} className={classes.discover}>
            <FooterHeader>{I18n.t("components.footer.discover.header")}</FooterHeader>
            <FooterLink href={Routes.new_demand_path()}>
              {I18n.t("components.footer.discover.tell_us_your_demands")}
            </FooterLink>
            <FooterLink href={Routes.accommodations_path()}>
              {I18n.t("components.footer.discover.search_our_listings")}
            </FooterLink>
            <FooterLink href={Routes.testimonials_path()}>
              {I18n.t("components.footer.discover.testimonials")}
            </FooterLink>
            <FooterLink href={FB_GROUP_URL}>
              {I18n.t("components.footer.discover.community")}
            </FooterLink>
            <FooterLink href={Routes.terms_and_conditions_path()}>
              {I18n.t("components.footer.discover.terms")}
            </FooterLink>
            <FooterLink href={Routes.privacy_policy_path()}>
              {I18n.t("components.footer.discover.privacy")}
            </FooterLink>
          </Grid>
          <Grid item xs={12} md={3}>
            <FooterHeader>{I18n.t("components.footer.contact_us")}</FooterHeader>
            <DyContactList />
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={classes.footer}>
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="body1">
              {I18n.t("components.footer.copyrights", { year: new Date().getFullYear() })}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}

export default DyFooter;
