const styles = theme => ({
  logo: {
    width: 168,
    background: "transparent"
  },
  toolbar: {
    justifyContent: "space-between",
    padding: 0,
    margin: "auto",
    width: "100%"
  },
  menuButton: {
    margin: theme.spacing(1)
  },
  fullWidth: {
    width: '100%'
  },
  appbar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1]
  }
});
export default styles;
