import "./translations";
import React  from "react";
import PropTypes from "prop-types";
// Material UI
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DyImage from "components/DyImage";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
// App
import DyProjectChip from "components/DyProjectChip";
import DyCompetitiveBadge from "components/DyCompetitiveBadge";
import priceDecorator from "utils/priceDecorator";
import concatPhotos from "utils/concatPhotos";
import accommodationTextDesc from "utils/accommodationTextDesc";
import ErrorAlt from "images/error_alt";
import styles from "./styles";

function DyAccommodationOverview(props) {
  const { classes, accommodation, ...others } = props;
  const title = accommodation.houseCode;
  const photos = concatPhotos(accommodation);
  const textDesc = accommodationTextDesc({
    bedroomCount: accommodation.bedroomCount,
    bathroomCount: accommodation.bathroomCount,
    housingCategoryName: accommodation.housingCategory.name,
    locationName: accommodation.estate.location.name
  })
  return (
    <Card
      component="a"
      href={accommodation.approved ? Routes.accommodation_path(accommodation.slug) : null}
      target="_blank"
      className={classes.card}
      elevation={0}
      {...others}
    >
      <DyImage
        lazyLoad
        imageProps={{
          src: (photos[0] && photos[0].originalUrl) || ErrorAlt,
          title: textDesc,
          alt: textDesc,
          className: classes.mediaImage
        }}
        containerProps={{
          className: classes.media,
        }}
      />
      <Hidden smDown>
        <DyProjectChip
          name={accommodation.estate.project && accommodation.estate.project.name}
          className={classes.projectChip}
        />
      </Hidden>
      <CardContent className={classes.cardContent}>
        {accommodation.estate.project && accommodation.estate.project.name &&
          <Hidden mdUp>
            <Typography component="p" variant="subtitle2" gutterBottom>
              {accommodation.estate.project.name}
            </Typography>
          </Hidden>
        }
        <Typography component="p" variant="body2" gutterBottom>
          {I18n.t("components.accommodation_overview.house_code")}{" "}
          <Typography component="span" color="primary">
            {accommodation.houseCode}
          </Typography>
          {" "}
          {accommodation.estate.competitiveValue > 0 && <DyCompetitiveBadge />}
        </Typography>
        <Typography component="p" variant="body2" gutterBottom>
          {textDesc}
        </Typography>
        <Typography
          component="p"
          gutterBottom
          color="secondary"
        >
          {priceDecorator({
            min: accommodation.minPrice,
            max: accommodation.maxPrice
          })}
        </Typography>
      </CardContent>
      {!accommodation.approved &&
        <CardActions>
          <Button disabled>{I18n.t("components.accommodation_overview.waiting_for_verification")}</Button>
        </CardActions>
      }
    </Card>
  );
}

DyAccommodationOverview.propTypes = {
  classes: PropTypes.object.isRequired
};

DyAccommodationOverview.defaultProps = {
  accommodation: {}
};
export default withStyles(styles)(DyAccommodationOverview);
