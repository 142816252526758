import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium
  },
  subtitle: {
    marginLeft: theme.spacing(1)
  },
  testimonialCont: {
    padding: theme.spacing(1/2)
  },
  tWrapper: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(15)
    }
  }
}));

export default styles;
