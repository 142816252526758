import "./translations";
import React from "react";
// App
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { DyLightTooltip } from "components/DyLightTooltip";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import styles from "./styles";

function DyCompetitiveBadge(props) {
  const classes = styles();
  const { className, ...ptProps } = props;
  return (
    <DyLightTooltip
      title={
        <React.Fragment>
          <Typography gutterBottom className={classes.colorGreen}>
            <b>{I18n.t("components.competitive_badge.header")}</b>
          </Typography>
          <Typography>
            {I18n.t("components.competitive_badge.desc")}
          </Typography>
        </React.Fragment>
      }
    >
      <VerifiedUserIcon
        {...ptProps}
        className={classnames(classes.colorGreen, className)}
      />
    </DyLightTooltip>
  );
}

export default DyCompetitiveBadge;
