import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  presenter: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3)
    }
  }
}));

export default styles;
