import bgImage from "images/bg";

const styles = theme => ({
  highlight: {
    // background: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    // backgroundImage: 'linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)'
    backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)"
  },
  highlightInner: {
    // color: theme.palette.common.white,
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 2)
    }
  },
  highlightBrief: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    }
  },
  highlightSubHeader: {
    marginBottom: theme.spacing(2)
  }
});

export default styles;
