import "typeface-roboto";
import "src/application.scss";
// import ahoy from "ahoy.js";
window.I18n = require("commons/i18n.js");
window.Routes = require("commons/routes.js.erb");
require("commons/translations.js");
I18n.locale = document.documentElement.getAttribute('lang');

// ahoy.trackAll();
var imagesContext = require.context("images", true);
var imagesPath = name => imagesContext(name, true);
// Uncomment the following line to enable i18n
// Routes.configure({default_url_options: {locale: I18n.locale}})

// import 'commons/initializer.js.erb';
var ReactRailsUJS = require("react_ujs");

var consoleLogo = `


██████╗ ██╗   ██╗███╗   ██╗ █████╗ ███╗   ███╗██╗███╗   ██╗██████╗ ███████╗
██╔══██╗╚██╗ ██╔╝████╗  ██║██╔══██╗████╗ ████║██║████╗  ██║██╔══██╗██╔════╝
██║  ██║ ╚████╔╝ ██╔██╗ ██║███████║██╔████╔██║██║██╔██╗ ██║██║  ██║███████╗
██║  ██║  ╚██╔╝  ██║╚██╗██║██╔══██║██║╚██╔╝██║██║██║╚██╗██║██║  ██║╚════██║
██████╔╝   ██║   ██║ ╚████║██║  ██║██║ ╚═╝ ██║██║██║ ╚████║██████╔╝███████║
╚═════╝    ╚═╝   ╚═╝  ╚═══╝╚═╝  ╚═╝╚═╝     ╚═╝╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝
                                                                           

$ Growing to believe that the housing market in Vietnam has various potential problems,
$ we - the Dynaminds - has been established with the hope to bring up market transparency
$ & information equality. 

$ We are a start-up Real Estate Agent Company and we value our Human Resource. We uphold
$ every member of us with a strong code of ethics and professional standards, knowing exactly
$ that is the value created us today. 

$ Hope that we’ll be your friendly neighborhood “housing-man” someday.

$ We are always seeking partners who is willing to learn, to face challenges & thrive with us,
$ Don't hesitate to apply!
$ Facebook Page: https://www.facebook.com/DynamindAgents/
$ Email: team@dynaminds.vn

$ P/S: We are using Ruby On Rails with Graphql API & React Material-UI, you can access our theme
$ object directly in the console

`;

console.log(`%c${consoleLogo}`, "color:#f50057;font-weight:bold;");