import "./translations";
import React from "react";
import PropTypes from "prop-types";
// Material UI
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import ContactsIcon from "@material-ui/icons/Contacts";
import SearchIcon from "@material-ui/icons/Search";
import GroupIcon from "@material-ui/icons/Group";
import SignOutIcon from "@material-ui/icons/ExitToApp";
import SignInIcon from "@material-ui/icons/HowToReg";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import RateReviewIcon from "@material-ui/icons/RateReview";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import truncate from "utils/truncate";
// App
import styles from "./styles";
import { FB_GROUP_URL } from "commons/initializer";
import currentUser from "commons/currentUser";
import DySignOutButton from "components/DySignOutButton";

function DyAppMenu(props) {
  const classes = styles();
  const [state, setState] = React.useState(false);

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  return (
    <nav>
      <IconButton
        aria-label={I18n.t("components.app_menu.mobile_menu_open_button")}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div
          className={classes.fullList}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem component="a" href={Routes.root_path()}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={I18n.t("components.app_menu.navigations.home")} />
            </ListItem>
            <ListItem component="a" href={Routes.accommodations_path()}>
              <ListItemIcon><SearchIcon /></ListItemIcon>
              <ListItemText primary={I18n.t("components.app_menu.navigations.listings")} />
            </ListItem>
            <ListItem component="a" href={Routes.testimonials_path()}>
              <ListItemIcon><RateReviewIcon /></ListItemIcon>
              <ListItemText primary={I18n.t("components.app_menu.navigations.testimonials")} />
            </ListItem>
            <ListItem
              component="a"
              href={FB_GROUP_URL}
              target="_blank"
              rel="noopener"
            >
              <ListItemIcon><GroupIcon /></ListItemIcon>
              <ListItemText
                primary={I18n.t("components.app_menu.navigations.fb_group")}
              />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </nav>
  );
}

export default DyAppMenu;
