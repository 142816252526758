export default function concatPhotos(accommodation) {
  let photos = [];
  if (accommodation.coverPhoto)
    photos = photos.concat([accommodation.coverPhoto])
  if (accommodation.photos)
    photos = photos.concat(accommodation.photos.nodes)
  if (accommodation.estate) {
    if (accommodation.estate.photos)
      photos = photos.concat(accommodation.estate.photos.nodes);
    if (accommodation.estate.coverPhoto)
      photos = photos.concat([accommodation.estate.coverPhoto]);
  }
  if (accommodation.estate.project) {
    if (accommodation.estate.project.coverPhoto)
      photos = photos.concat([accommodation.estate.project.coverPhoto]);
    if (accommodation.estate.project.photos)
      photos = photos.concat(accommodation.estate.project.photos.nodes);
    return photos;
  } else {
    return photos;
  }
};