import React from "react";
import withSnackbar from "notistack/build/withSnackbar";
import withSnackbarProvider from "./withSnackbarProvider";

function withNotistack(Component) {
  const ChildrenWithSnackbar = withSnackbar(Component);
  const SnackUserWithProvider = withSnackbarProvider(ChildrenWithSnackbar);
  return function NotistackIntegrated(props) {
    return <SnackUserWithProvider {...props} />;
  }
}

export default withNotistack;