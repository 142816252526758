export default function timeReadableOf(rails_date) {
  if (!rails_date) {
    return "";
  }
  const d = new Date(rails_date);
  if (I18n.locale == "vi") {
    return I18n.strftime(d, "%A, %d %B, %Y");
  } else {
    return I18n.strftime(d, "%A, %B %d, %Y");
  }
};