import React from "react";
// App
import Typography from "@material-ui/core/Typography";

function DyPageHeadline(props) {
  return (
    <Typography
      component="h1"
      variant="h4"
      gutterBottom
      {...props}
    />
  )
}

export default DyPageHeadline;
