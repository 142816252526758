import "./translations";
import React from "react";
import PropTypes from "prop-types";
// App
import Chip from "@material-ui/core/Chip";
import LocationCity from "@material-ui/icons/LocationCity";

function DyProjectChip(props) {
  const { name, ...others } = props;
  return (
    <Chip
      variant="default"
      color={props.name ? "primary" : "default"}
      size="small"
      icon={<LocationCity />}
      label={props.name || I18n.t("components.project_chip.not_in_project")}
      {...others}
    />
  );
}

DyProjectChip.propTypes = {
  name: PropTypes.string
};

export default DyProjectChip;
