import React from "react";
// Material UI
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fade from "@material-ui/core/Fade";
// App
import baseTheme from "styles/baseTheme";
import styles from "./styles";

function DyAppLayout(props) {
  const classes = styles();
  return (
    <MuiThemeProvider theme={baseTheme}>
      <CssBaseline />
      <Fade in>
        <main className={classes.layout}>{props.children}</main>
      </Fade>
    </MuiThemeProvider>
  );
}

export default DyAppLayout;
