import React from "react";
import SnackbarProvider from "notistack/build/SnackbarProvider";

function withSnackbarProvider(Component) {
  return function SnackbarProviderIntegrated(props) {
    return (
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        autoHideDuration={2000}
      >
        <Component {...props} />
      </SnackbarProvider>
    );
  }
}

export default withSnackbarProvider;