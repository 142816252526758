import "./translations";
import React, { useState } from "react";
// Material UI
import DyTestimonial from "components/DyTestimonial";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/styles";
import gpl from "graphql-tag";
import { Query } from "@apollo/react-components";
import DyHorizontalScroll from "components/DyHorizontalScroll";
// App
import DyTestimonialDialog from "components/DyTestimonialDialog";
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const GET_TESTIMONIALS = gpl`
  query {
    testimonials(first: 5) {
      nodes {
        id
        name
        content
        coverPhoto {
          thumbUrl
        }
        receivedDate
      }
    }
  }
`;

function DyTestimonialSlider(props) {
  const classes = styles();
  const [testimonialStatus, setTestimonialStatus] = useState({
    open: false
  });
  const { open, ...currentTestimonial } = testimonialStatus;

  return (
    <Query
      query={GET_TESTIMONIALS}
      onCompleted={data =>
        setTestimonialStatus({
          open: false,
          ...data.testimonials[0]
        })
      }
    >
      {({ loading, error, data }) => {
        if (loading || error)
          return (
            <Container
              className={classes.tWrapper}
              component="section"
              maxWidth="lg"
              style={{ textAlign: "center" }}
            >
              <CircularProgress />
            </Container>
          );
        return (
          <>
            <DyHorizontalScroll xs={1} sm={2} md={3}
              title={I18n.t("components.testimonial_slider.what_customer_said")}
              className={classes.tWrapper}
            >
              {data.testimonials.nodes.map(testimonial => (
                <DyTestimonial
                  key={testimonial.id}
                  testimonial={testimonial}
                  className={classes.testimonialCont}
                  handleShowingDetails={setTestimonialStatus}
                />
              ))}
            </DyHorizontalScroll>
            <DyTestimonialDialog
              open={open}
              testimonial={currentTestimonial}
              handleCloseDetails={setTestimonialStatus}
            />
          </>
        );
      }}
    </Query>
  );
}

export default DyTestimonialSlider;
