import "./translations";
import React from "react";
// App
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ORG_EMAIL, FB_PAGE_URL, ORG_HOTLINE } from "commons/initializer";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import styles from "./styles";

function ContactList(props) {
  return <List dense disablePadding {...props} />;
}

function ContactItem(props) {
  const classes = styles();
  const { text, icon, ...ptProps } = props;
  return (
    <ListItem component="a" disableGutters className={classes.contactLink} {...ptProps}>
      <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
}

function DyContactList(props) {
  return (
    <ContactList>
      <ContactItem
        href={FB_PAGE_URL}
        target="_blank"
        icon={<FacebookIcon color="primary" />}
        text={I18n.t("components.contact_list.facebook_page")}
      />
      <ContactItem
        href={`mailto: ${ORG_EMAIL}`}
        icon={<EmailIcon color="secondary" />}
        text={ORG_EMAIL}
      />
      <ContactItem
        href={`tel: ${ORG_HOTLINE}`}
        icon={<PhoneIcon color="primary" />}
        text={ORG_HOTLINE}
      />
    </ContactList>
  )
}

export default DyContactList;
