import "./translations";
import React, { PureComponent } from "react";
import Proptypes from "prop-types";
// Material UI
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import NearMeIcon from "@material-ui/icons/NearMe";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Hidden from "@material-ui/core/Hidden";
// App
import styles from "./styles";

function DyHighlight1(props) {
  const classes = styles();
  return (
    <section className={classes.hightlight}>
      <Container maxWidth="xl" className={classes.highlightInner}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} className={classes.highlightHeader}>
            <Typography
              component="h1"
              variant="h3"
              color="inherit"
              align="center"
              gutterBottom
            >
              {I18n.t("components.highlight1.header")}
            </Typography>
            <Typography
              variant="h5"
              color="inherit"
              align="center"
              component="h2"
            >
              {I18n.t("components.highlight1.sub_header_1")}
              {/* <br/>
              {I18n.t("components.highlight1.sub_header_2")} */}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.buttonGroupCont}>
            <Hidden smUp>
              <Button
                size="large"
                variant="contained"
                color="primary"
                component="a"
                href={Routes.accommodations_path()}
                className={classes.button}
                fullWidth
              >
                <SearchIcon className={classes.leftIcon} />
                {I18n.t("components.highlight1.search_listing")}
              </Button>
              <Button
                component="a"
                size="large"
                variant="contained"
                color="primary"
                href={Routes.new_demand_path()}
                className={classes.button}
                fullWidth
              >
                <NearMeIcon className={classes.leftIcon} />
                {I18n.t("components.highlight1.send_us")}
              </Button>
            </Hidden>
            <Hidden xsDown>
              <ButtonGroup
                size="large"
                variant="contained"
                color="primary"
                aria-label="Small outlined button group"
              >
                <Button
                  component="a"
                  href={Routes.accommodations_path()}
                >
                  <SearchIcon className={classes.leftIcon} />
                  {I18n.t("components.highlight1.search")}
                </Button>
                <Button
                  component="a"
                  href={Routes.new_demand_path()}
                >
                  <NearMeIcon className={classes.leftIcon} />
                  {I18n.t("components.highlight1.send_us")}
                </Button>
              </ButtonGroup>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

DyHighlight1.propTypes = {};

export default DyHighlight1;
