import React from "react";
import PropTypes from "prop-types";
import lazyImgProps from "utils/lazyImgProps";
import Avatar from "@material-ui/core/Avatar";
import Dot from "images/dot";

function DyAvatar(props) {
  const { lazyLoad, src, imgProps, ...ptProps } = props;
  if (lazyLoad) {
    const { src: newSrc, ...newImgProps } = lazyImgProps({ ...imgProps, src });
    return <Avatar {...ptProps} src={newSrc || Dot} imgProps={newImgProps} />;
  } else {
    return <Avatar src={src} imgProps={imgProps} {...ptProps} />;
  }
}

DyAvatar.defaultProps = {
  lazyLoad: false
};

DyAvatar.propTypes = {
  lazyLoad: PropTypes.bool
};

export default DyAvatar;
