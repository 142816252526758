import { makeStyles } from "@material-ui/core/styles";
import { prettyLink } from "styles/prettyLink";

const styles = makeStyles(theme => ({
  contactLink: prettyLink(theme),
  itemIcon: {
    minWidth: theme.spacing(4)
  }
}));

export default styles;
