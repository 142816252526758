import { makeStyles } from "@material-ui/core/styles";
import { prettyLink } from "styles/prettyLink";

const styles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.common.white
  },
  footerLink: prettyLink(theme),
  discover: {
    marginBottom: theme.spacing(1)
  },
  about: {
    textAlign: "justify",
    textJustify: "auto",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      width: "90%"
    }
  }
}));

export default styles;

