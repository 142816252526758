export default function priceDecorator(price) {
  if (!price || !price.min) {
    return price.default || I18n.t("utils.price_decorator.contact_us_for_price");
  }
  const options = { precision: 0 };
  if ((price.min && !price.max) || price.min == price.max) {
    return I18n.toCurrency(price.min, options);
  }
  return `${I18n.toCurrency(price.min, options)} - ${I18n.toCurrency(
    price.max,
    options
  )}`;
};