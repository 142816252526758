export default function accommodationTextDesc(accommodation) {
  let arr = [];
  if (accommodation.bedroomCount) {
    arr.push(I18n.t("utils.accommodation_text_desc.bedrooms", {
      count: accommodation.bedroomCount
    }));
  }
  if (accommodation.bathroomCount) {
    arr.push(I18n.t("utils.accommodation_text_desc.bathrooms", {
      count: accommodation.bathroomCount
    }));
  }
  if (accommodation.housingCategoryName) {
    arr.push(accommodation.housingCategoryName);
  }
  if (accommodation.locationName) {
    arr.push(`in ${accommodation.locationName}`);
  }
  return arr.join(" ");
};