import React, { useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import common from "@material-ui/core/colors/common";
import makeStyles from "./styles";
import classnames from "classnames";
import BrokenImage from "@material-ui/icons/BrokenImage";
import lazyImgProps from "utils/lazyImgProps";
import merge from "lodash/fp/merge";
import grey from "@material-ui/core/colors/grey";

export default function DyImage(props) {
  const {
    children,
    animationDuration,
    disableError,
    disableSpinner,
    errorIcon,
    loading,
    containerProps,
    iconContainerProps,
    imageProps: { className: imgClassName, ...ptImgProps },
    lazyLoad
  } = props;
  const { component: ContComp, className: contClassName, ...ptContProps } = merge(
    DyImage.defaultProps.containerProps,
    containerProps
  );
  const { component: IconContComp, className: iconContClassName, ...ptIconContProps } = merge(
    DyImage.defaultProps.iconContainerProps,
    iconContainerProps
  );
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const classes = makeStyles({ animationDuration, imageLoaded });
  // Dependent on utils/makeImagesLazy.js
  let imgProps = ptImgProps;
  if (lazyLoad) imgProps = lazyImgProps(ptImgProps);

  function handleLoadImage() {
    setImageLoaded(true);
  }

  function handleImageError() {
    if (ptImgProps.src) setImageError(true);
  }

  return (
    <ContComp
      className={classnames(classes.root, contClassName)}
      {...ptContProps}
    >
      {ptImgProps.src && (
        <img
          {...imgProps}
          className={classnames(classes.image, imgClassName)}
          onLoad={handleLoadImage}
          onError={handleImageError}
        />
      )}
      <IconContComp
        className={classnames(classes.iconContainer, iconContClassName)}
        {...ptIconContProps}
      >
        {!disableSpinner && !imageLoaded && !imageError && loading}
        {!disableError && imageError && errorIcon}
      </IconContComp>
      {children}
    </ContComp>
  );
}

DyImage.propTypes = {
  /** Duration of the fading animation, in milliseconds. */
  animationDuration: PropTypes.number,
  /** Override aspect ratio. */
  /** Disables the error icon if set to true. */
  disableError: PropTypes.bool,
  /** Disables the loading spinner if set to true. */
  disableSpinner: PropTypes.bool,
  /** Override the error icon. */
  errorIcon: PropTypes.node,
  /** Override the loading component. */
  loading: PropTypes.node,
  containerProps: PropTypes.object,
  iconContainerProps: PropTypes.object,
  imageProps: PropTypes.object
};

DyImage.defaultProps = {
  animationDuration: 3000,
  disableError: false,
  disableSpinner: false,
  errorIcon: (
    <BrokenImage style={{ width: 48, height: 48, color: grey[100] }} />
  ),
  loading: <CircularProgress size={48} />,
  containerProps: { component: 'div' },
  iconContainerProps: { component: 'div' }
};
