import bgImage from "images/bg_1";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  hightlight: {
    // maxWidth: '1300px',
    // margin: "auto",
    background: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  highlightInner: {
    padding: theme.spacing(8, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(14, 2)
    }
  },
  highlightHeader: {
    marginBottom: theme.spacing(5),
    color: theme.palette.common.white
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  buttonGroupCont: {
    textAlign: 'center'
  },
  button: {
    marginBottom: theme.spacing(1),
    width: '310px',
    maxWidth: '90vw'
  }
}));

export default styles;
