import "./translations";
import React, { useState } from "react";
import PropTypes from "prop-types";
// App
import DyHorizontalScroll from "components/DyHorizontalScroll";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DyAccommodationOverview from "components/DyAccommodationOverview";
import gpl from "graphql-tag";
import { Query } from "@apollo/react-components";
import styles from "./styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import DyHidden from "components/DyHidden";

const GET_ACCOMMODATIONS = gpl`
  query($locationIds: [ID!]) {
    accommodations(
      first: 8,
      locationIds: $locationIds,
      sorts: ["related_agency_name ASC", "estate_competitive_value DESC", "created_at DESC"]
    ) {
      nodes {
        approved
        slug
        houseCode
        coverPhoto {
          originalUrl(maxSize: "400x400")
        }
        housingCategory {
          name
        }
        minPrice
        minArea
        bedroomCount
        bathroomCount
        estate {
          competitiveValue
          coverPhoto {
            originalUrl(maxSize: "400x400")
          }
          project {
            name
            coverPhoto {
              originalUrl(maxSize: "400x400")
            }
          }
          location {
            name
          }
        }
      }
    }
  }
`;

function DyLocationCollection(props) {
  const classes = styles();
  const { collection, ...contProps } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const makeMeActive = (index) => () => {
    setActiveIndex(index);
  }
  return (
    <div {...contProps}>
      <DyHorizontalScroll xs={2} sm={5} md={7} xsHeight="auto">
        {collection.collectionObjects.map((collectionObject, index) => {
          return (
            <Button variant="outlined" color={activeIndex == index ? "primary" : "default"}
              onClick={makeMeActive(index)} key={`DISTRICT-${index}-BUTTON`}
            >
              {collectionObject.objectable.name}
            </Button>
          );
        })}
      </DyHorizontalScroll>
      <Container maxWidth="xl">
        {collection.collectionObjects.map((collectionObject, index) => {
          let district = collection.collectionObjects[activeIndex].objectable;
          return (
            <DyHidden active={index == activeIndex} key={`DISTRICT-${index}-ACCOMMODATIONS`}>
              <Grid container spacing={3} className={classes.presenter}>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    variant="h5"
                    align="center"
                  >
                    {I18n.t("components.location_collection.featured_accommodations", {
                      name: district.name
                    })}
                  </Typography>
                </Grid>
                <Query query={GET_ACCOMMODATIONS} variables={{
                  locationIds: [district.id]
                }}>
                  {({ data, loading, error }) => {
                    if (loading || error) return (
                      <Grid item xs={12} style={{ "textAlign": "center" }}>
                        <CircularProgress />
                      </Grid>
                    );
                    let results = data.accommodations.nodes.map(node => (
                      <Grid item xs={6} sm={6} md={3} key={node.houseCode}>
                        <DyAccommodationOverview accommodation={node} />
                      </Grid>
                    ));
                    results.push(
                      <Grid item xs={12} key={`DISTRICT-${district.id}-SEE-MORE`} style={{ "textAlign": "center" }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          component="a"
                          target="_blank"
                          href={Routes.accommodations_path({
                            locationIds: district.id
                          })}
                        >
                          {I18n.t("components.location_collection.see_all", {
                            n: district.accommodationsCount,
                            name: district.name
                          })}
                        </Button>
                      </Grid>
                    );
                    return results;
                  }}
                </Query>
              </Grid>
            </DyHidden>
          );
        })}
      </Container>
    </div>
  );
}

DyLocationCollection.propTypes = {
  collection: PropTypes.object.isRequired
};

export default DyLocationCollection;
