import React from "react";
import PropTypes from "prop-types";
// App
import gpl from "graphql-tag";
import { Query } from "@apollo/react-components";
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import DyProjectCollection from "components/DyProjectCollection";
import DyLocationCollection from "components/DyLocationCollection";

const GET_IN_HOME_COLLECTIONS = gpl`
  query {
    collections(placementEq: in_home) {
      edges {
        node {
          id
          title
          subtitle
          collectionType
          collectionObjects {
            objectable {
              ...on Location {
                id
                name
                accommodationsCount
              }
              ...on Project {
                id
                name
                accommodationsCount
                locationId
                coverPhoto {
                  id
                  originalUrl(maxSize: "500x500")
                }
              }
            }
          }
        }
      }
    }
  }
`;
function DyLandingCollections(props) {
  const classes = styles();
  return (
    <Query query={GET_IN_HOME_COLLECTIONS}>
      {({ data, loading, error }) => {
        if (loading || error)
          return (
            <div className={classes.collection} style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          );
        return data.collections.edges.map(edge => {
          if (edge.node.collectionType == "project") {
            return (
              <DyProjectCollection
                className={classes.collection}
                collection={edge.node}
                key={`COLLECTION-${edge.node.id}`}
              />
            );
          } else {
            return (
              <DyLocationCollection
                className={classes.collection}
                key={`COLLECTION-${edge.node.id}`}
                collection={edge.node}
              />
            );
          }
        });
      }}
    </Query>
  );
}

export default DyLandingCollections;
