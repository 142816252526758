import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function DyHidden(props) {
  const { active, children, ...others } = props;
  const [mountedOnce, setMountedOnce] = useState(active);
  const style = props.active ? undefined : { display: "none" };

  useEffect(() => {
    if (props.active) setMountedOnce(true);
  }, [active]);

  return (
    <div {...others} style={style}>
      {mountedOnce && children}
    </div>
  );
}

DyHidden.propTypes = {
  active: PropTypes.bool.isRequired
};

DyHidden.defaultProps = {
  active: true
};

export default DyHidden;
