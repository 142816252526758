import "./translations";
import React from "react";
import PropTypes from "prop-types";
// Material-UI
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ThumbUpOutlined from "@material-ui/icons/ThumbUpOutlined";
import SentimentSatisfiedAltOutlined from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import InsertChartOutlinedOutlined from "@material-ui/icons/InsertChartOutlinedOutlined";
import LocalOfferOutlined from "@material-ui/icons/LocalOfferOutlined";
// App
import styles from "./styles";

const services = [
  {
    icon: ThumbUpOutlined,
    name: I18n.t("components.landing_services.names")[0],
    desc: I18n.t("components.landing_services.descs")[0]
  },
  {
    icon: SentimentSatisfiedAltOutlined,
    name: I18n.t("components.landing_services.names")[1],
    desc: I18n.t("components.landing_services.descs")[1]
  },
  {
    icon: InsertChartOutlinedOutlined,
    name: I18n.t("components.landing_services.names")[2],
    desc: I18n.t("components.landing_services.descs")[2]
  },
  {
    icon: LocalOfferOutlined,
    name: I18n.t("components.landing_services.names")[3],
    desc: I18n.t("components.landing_services.descs")[3]
  }
];

function ServiceIcon(props) {
  const Component = props.component;
  return <Component className={props.className} />;
}

function DyLandingServices(props) {
  const classes = styles();
  return (
    <Container
      maxWidth="md"
      component="section"
      className={classes.servicesWrapper}
    >
      <Grid container spacing={2} justify="center">
        {services.map((service, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            className={classes.serviceWrapper}
            key={index}
          >
            <Card elevation={0}>
              <ServiceIcon
                className={classes.serviceIcon}
                component={service.icon}
              />
              <CardContent>
                <Typography component="h2" variant="h5" gutterBottom>
                  {service.name}
                </Typography>
                <Typography
                  component="p"
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {service.desc}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

DyLandingServices.propTypes = {};

export default DyLandingServices;
