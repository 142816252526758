import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const styles = makeStyles(theme => ({
  root: {
    backgroundColor: grey[100],
    position: "relative",
    display: "block"
  },
  image: params => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    objectFit: "cover",
    opacity: params.imageLoaded ? 1 : 0,
    filterBrightness: params.imageLoaded ? 100 : 0,
    filterSaturate: params.imageLoaded ? 100 : 20,
    transition: `filterBrightness ${params.animationDuration * 0.75}ms cubic-bezier(0.4, 0.0, 0.2, 1),
                  filterSaturate ${params.animationDuration}ms cubic-bezier(0.4, 0.0, 0.2, 1),
                  opacity ${params.animationDuration / 2}ms cubic-bezier(0.4, 0.0, 0.2, 1)`
  }),
  iconContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none"
  }
}));

export default styles;
