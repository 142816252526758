import { makeStyles } from "@material-ui/core/styles";
import green from '@material-ui/core/colors/green';

const styles = makeStyles(theme => ({
  colorGreen: {
    color: green[600],
    cursor: 'pointer'
  }
}));

export default styles;
