import React, { PureComponent } from "react";
// App
import getCSRFToken from "utils/getCSRFToken";

class DySignOutButton extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <form method="post" action={Routes.destroy_user_session_path()}>
        <input type="hidden" name="_method" value="delete" />
        {children}
        <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
      </form>
    );
  }
}

export default DySignOutButton;

/** // sign out button using mutation sign out

  import React, { PureComponent } from "react";
  import React, { PureComponent } from "react";
  // Material UI
  import Button from "@material-ui/core/Button";
  import MenuItem from "@material-ui/core/MenuItem";
  // Apollo
  import gpl from "graphql-tag";
  import { Mutation } from "@apollo/react-components";
  // App
  import currentUser from "commons/currentUser";

  const SIGN_OUT = gpl`
    mutation {
      signedOut: signOut
    }
  `;

  class DySignOutButton extends PureComponent {
    onSignedOutSuccessfulOrNot = () => {
      currentUser.clear();
      location.href = Routes.root_url();
    }

    render() {
      const { onClick, href, isMenuItem, ...others } = this.props;
      const SignOutComponent = isMenuItem ? MenuItem : Button;
      return (
        <Mutation mutation={SIGN_OUT}
          onError={this.onSignedOutSuccessfulOrNot}
          onCompleted={this.onSignedOutSuccessfulOrNot}
        >
          {(signOut, { data, loading, error }) => (
            <SignOutComponent {...others} onClick={signOut}>
              {I18n.t("components.sign_out_button.label")}
            </SignOutComponent>
          )}
        </Mutation>
      );
    }
  }

  export default DySignOutButton;

**/
