import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  serviceIcon: {
    fontSize: theme.typography.pxToRem(55),
    color: theme.palette.primary.light
  },
  servicesWrapper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(10)
  },
  serviceWrapper: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
  },
  service: {
    width: 60,
    height: 60,
    margin: "auto"
  }
}));

export default styles;
