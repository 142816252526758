import isEmpty from "lodash/fp/isEmpty";

export default {
  // data: JSON.parse(localStorage.getItem('currentUser')) || {},
  data: gon.currentUser || {},
  get id() {
    return this.data.id;
  },
  get name() {
    return this.data.name;
  },
  get email() {
    return this.data.email;
  },
  get token() {
    return this.data.token;
  },
  get signedIn() {
    return !isEmpty(this.data);
  },
  get role() {
    return this.data.role;
  }
  // refresh: function() {
  //   this.data = JSON.parse(localStorage.getItem('currentUser'));
  //   return this;
  // },
  // persist: function(user) {
  //   localStorage.setItem('currentUser', JSON.stringify(user));
  //   return this.refresh();
  // },
  // clear: function() {
  //   localStorage.removeItem('currentUser');
  //   this.data = {};
  //   return this;
  // }
};