import React from "react";
// Apollo
import ApolloClient from "apollo-client";
import { ApolloProvider } from "@apollo/react-common";
// App
import currentUser from "commons/currentUser";
import getCSRFToken from "utils/getCSRFToken";
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from 'commons/fragmentTypes.json';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, from } from 'apollo-link';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

const httpLink = new HttpLink({
  uri: '/graphql',
  credentials: 'same-origin',
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "X-CSRF-Token": getCSRFToken(),
      // Authorization: `Bearer ${currentUser.token}` || null,
    }
  }));

  return forward(operation);
})

function createContextMiddleware(context={}) {
  const ctx = {
    "Enable-Graphql-Cache": true,
    ...context
  }
  return new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...ctx
      }
    }));
  
    return forward(operation);
  })
}

function createApolloClient(context={}) {
  return new ApolloClient({
    cache,
    link: from([
      authMiddleware,
      createContextMiddleware(context),
      httpLink
    ])
  });
}

function DyApolloProvider(props) {
  const client = createApolloClient(props.context)
  return (
    <ApolloProvider client={client}>
      {props.children}
    </ApolloProvider>
  );
}

export default DyApolloProvider;
