const observeDOM = (function () {
  let MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

  return function (obj, callback) {
    if (!obj || !obj.nodeType === 1) return; // validation

    if (MutationObserver) {
      // define a new observer
      let obs = new MutationObserver(function (mutations, observer) {
        callback(mutations);
      })
      // have the observer observe foo for changes in children
      obs.observe(obj, { childList: true, subtree: true });
    }

    else if (window.addEventListener) {
      obj.addEventListener('DOMNodeInserted', callback, false);
      obj.addEventListener('DOMNodeRemoved', callback, false);
    }
  }
})();

export default observeDOM;