import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  media: {
    height: 200,
    [theme.breakpoints.up('sm')]: {
      height: 300
    },
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(1/2)
  },
  mediaImage: {
    borderRadius: theme.shape.borderRadius
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black
  }
}));

export default styles;
