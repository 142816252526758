import "./translations";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

function DyTestimonialDialog(props) {
  const { open, handleCloseDetails, testimonial } = props;
  const handleClose = (() => handleCloseDetails({ open: false, ...testimonial }));
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">{testimonial.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>{testimonial.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
        {I18n.t('components.testimonial_dialog.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DyTestimonialDialog;
