import red from "@material-ui/core/colors/red";
const styles = theme => ({
  media: {
    height: 150,
    [theme.breakpoints.up('sm')]: {
      height: 200
    },
    backgroundColor: theme.palette.grey[100]
  },
  mediaImage: {
    borderRadius: theme.shape.borderRadius
  },
  cardContent: {
    padding: 0,
    paddingTop: theme.spacing(1)
  },
  facilities: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  },
  icon: {
    margin: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: red[800]
    }
  },
  card: {
    position: "relative",
    display: "block",
    textDecoration: "none"
  },
  projectChip: {
    position: "absolute",
    top: 0,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
});

export default styles;
