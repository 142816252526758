import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  collection: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(15)
    }
  }
}));

export default styles;
