import "./translations";
import React from "react";
import PropTypes from "prop-types";
// App
import styles from "./styles";
import Typography from "@material-ui/core/Typography";
import DyImage from "components/DyImage";
import DyHorizontalScroll from "components/DyHorizontalScroll";

function DyProjectCollection(props) {
  const classes = styles();
  const { collection, ...contProps } = props;
  return (
    <DyHorizontalScroll sm={3} md={5} title={collection.title} subtitle={collection.subtitle} {...contProps}>
      {collection.collectionObjects.map(collectionObject => {
        const title = [
          collection.title,
          collection.subtitle,
          `IMG ${collectionObject.objectable.coverPhoto.id}`
        ].join(", ");
        return (
          <a
            href={Routes.accommodations_path({
              projectIds: collectionObject.objectable.id,
              locationIds: collectionObject.objectable.locationId
            })}
            target="_blank"
            className={classes.link}
            key={`OBJECTABLE-${collectionObject.objectable.id}`}
          >
            <DyImage
              lazyLoad
              imageProps={{
                src: collectionObject.objectable.coverPhoto.originalUrl,
                title: title,
                alt: title,
                className: classes.mediaImage,
              }}
              containerProps={{
                className: classes.media
              }}
            />
            <Typography
              variant="body2"
              component="p"
              className={classes.projectTitle}
            >
              {collectionObject.objectable.name}
            </Typography>
            <Typography variant="body2" component="p" color="primary">
              {I18n.t("components.project_collection.n_accommodations", {
                n: collectionObject.objectable.accommodationsCount
              })}
            </Typography>
          </a>
        );
      })}
    </DyHorizontalScroll>
  );
}

DyProjectCollection.propTypes = {
  collection: PropTypes.object.isRequired
};

export default DyProjectCollection;
