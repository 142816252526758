const styles = theme => ({
  wrapper: {
    borderBottom: `${theme.spacing(1/4)}px ${theme.palette.primary.light} solid`
  },
  content: {
    height: 77
  }
});

export default styles;
