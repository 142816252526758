import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium
  },
  container: {
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  header: {
    gridColumn: "1 / -1",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  body: {
    position: "relative",
    display: "grid",
    gridGap: theme.spacing(2, 0),
    gridTemplateColumns: `${theme.spacing(2)}px 1fr ${theme.spacing(2)}px`
  },
  item: props => ({
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(1),
      flex: `1 0 calc((100% - ${theme.spacing(props.sm - 1)}px)/${props.sm})`
    },
    [theme.breakpoints.up("md")]: {
      flex: `1 0 calc((100% - ${theme.spacing(props.md - 1)}px)/${props.md})`
    }
  }),
  hs: {
    width: "100%",
    overflowX: "scroll",
    scrollbarWidth: "none",
    scrollSnapType: "x proximity",
    gridColumn: "1 / -1",
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridGap: theme.spacing(1),
      gridTemplateColumns: theme.spacing(1),
      gridTemplateRows: props => `minmax(${props.xsHeight}, 1fr)`,
      gridAutoFlow: "column",
      gridAutoColumns: props => `calc(${100 / props.xs}% - ${theme.spacing(4 / props.xs)}px * 2)`,
      "&:before": {
        content: "''",
        width: theme.spacing(1)
      },
      "&:after": {
        content: "''",
        width: theme.spacing(1)
      }
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    },
    "&::-webkit-scrollbar": {
      width: 0
    }
  },
  nextArrow: {
    backgroundColor: theme.palette.common.white,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: -theme.spacing(2),
    margin: "auto",
    zIndex: theme.zIndex.mobileStepper,
    "&:hover": {
      backgroundColor: theme.palette.common.white
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    boxShadow: theme.shadows[3]
  },
  prevArrow: {
    backgroundColor: theme.palette.common.white,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: -theme.spacing(2),
    margin: "auto",
    zIndex: theme.zIndex.mobileStepper,
    "&:hover": {
      backgroundColor: theme.palette.common.white
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    boxShadow: theme.shadows[3]
  }
}));

export default styles;
