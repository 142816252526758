import {
  createMuiTheme,
  responsiveFontSizes
} from "@material-ui/core/styles";

let baseTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#007ce8"
    }
  }
});

baseTheme = responsiveFontSizes(baseTheme, { factor: 3 });
window.theme = baseTheme;
export default baseTheme;