import React, { useState } from "react";
import PropTypes from "prop-types";
// App
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from "@material-ui/core/Fab";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import DyPageHeadline from "components/DyPageHeadline";
import classnames from "classnames";
import styles from "./styles";

function DyHorizontalScroll(props) {
  const { children, title, subtitle, xs, xsHeight, sm, md, className, ...ptProps } = props;
  const childrenCount = children.length;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollContainerRef, setScrollContainerRef] = useState(null);
  const classes = styles({
    currentIndex: currentIndex,
    xs: xs,
    xsHeight: xsHeight,
    sm: sm,
    md: md
  });
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  let displayCount;
  let noIndex = false;
  if (isMd) {
    displayCount = md;
  } else if (isSm) {
    displayCount = sm;
  } else {
    noIndex = true;
  }
  function modifiedChild(child, index) {
    let props = { ...child.props, className: classnames(child.props.className, classes.item) };
    return React.cloneElement(child, props);
  }
  const nextable = childrenCount - currentIndex > displayCount;
  const prevable = currentIndex > 0;
  function slickNext() {
    if (nextable) {
      scrollContainerRef.scrollBy({
        left: (scrollContainerRef.clientWidth - theme.spacing(displayCount - 1)) / displayCount + theme.spacing(1),
        behavior: "smooth"
      });
      setCurrentIndex(currentIndex + 1);
    }
  }

  function slickPrev() {
    if (prevable) {
      scrollContainerRef.scrollBy({
        left: -(scrollContainerRef.clientWidth - theme.spacing(displayCount - 1)) / displayCount - theme.spacing(1),
        behavior: "smooth"
      });
      setCurrentIndex(currentIndex - 1);
    }
  }
  return (
    <Container
      component="section"
      maxWidth="lg"
      className={classnames(classes.container, className)}
      {...ptProps}
    >
      {title && <DyPageHeadline
        component="p"
        className={classes.header}
      >
        {title}
      </DyPageHeadline>}
      {subtitle && <Typography
        variant="body1"
        color="textSecondary"
        className={classes.header}
        gutterBottom
      >
        {subtitle}
      </Typography>}
      <div className={classes.body}>
        {prevable &&
          <Fab size="small" className={classes.prevArrow} onClick={slickPrev}>
            <NavigateBeforeIcon />
          </Fab>
        }
        {nextable &&
          <Fab size="small" className={classes.nextArrow} onClick={slickNext}>
            <NavigateNextIcon />
          </Fab>
        }
        <div className={classes.hs} ref={ref => setScrollContainerRef(ref)}>
          {React.Children.map(children, (child, index) => {
            return modifiedChild(child, index);
          })}
        </div>
      </div>
    </Container>
  );
}

DyHorizontalScroll.propTypes = {
  sm: PropTypes.number,
  md: PropTypes.number,
  xs: PropTypes.oneOf([1, 2]),
  xsHeight: PropTypes.string
};

DyHorizontalScroll.defaultProps = {
  sm: 3,
  md: 5,
  xs: 2,
  xsHeight: '150px'
};

export default DyHorizontalScroll;
