export const ORG_NAME = I18n.t("seo.initializers.site_name");
export const FB_APP_ID = I18n.t("seo.initializers.fb_app_id");
export const FB_PAGE_URL = I18n.t("seo.initializers.fb_page_url");
export const FB_GROUP_URL = I18n.t("seo.initializers.fb_group_url");
export const ORG_EMAIL = I18n.t("seo.initializers.org_mail");
export const ORG_HOTLINE = I18n.t("seo.initializers.org_hotline");
export const LOCALES = ["en", "vi"];
// The google map api key need not to be protected since it'll be included in a header script
// anyway Google have taken the security measure by restricted the key to our domain only.
export const GOOGLE_MAP_API_KEY = "AIzaSyAjGbCWFzEFVz5MfiaHlvQ2p9hdnJJ05iU";

export const changeLocaleTo = new_locale => {
  let next_locale = new_locale || I18n.locale;
  return Routes.root_url({locale: next_locale});
};

export const DEFAULT_LOCATION_ID = 2;

export const ACCOMMODATION_FILTERS = {
  BEDROOMS: [1, 2, 3, 4, 5, 6],
  BATHROOMS: [1, 2, 3, 4, 5, 6],
  PRICES: [
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
    1000,
    2000,
    3000,
    20000
  ],
  AREA: {
    MIN: 25,
    MAX: 200
  }
};

export const USER_ROLES = [
  { label: "", value: "" },
  { label: I18n.t("seo.initializers.label.landlord_role"), value: "landlord" },
  {
    label: I18n.t("seo.initializers.label.client_role"),
    value: "client"
  },
  { label: I18n.t("seo.initializers.label.agent_role"), value: "agent" }
];

export const USER_GENDERS = [
  { label: "", value: "" },
  { label: I18n.t("seo.initializers.label.male"), value: "male" },
  { label: I18n.t("seo.initializers.label.female"), value: "female" },
  { label: I18n.t("seo.initializers.label.others"), value: "others" }
];

export const ATTACHMENTS = {
  COVER_PHOTO: "cover_photo",
  PHOTO: "photo"
}