import "./translations";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Material UI
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// classnames for jss
import classNames from "classnames";
// App
import { FB_GROUP_URL } from "commons/initializer";
import Logo from "images/logo";
import DyAppMenu from "components/DyAppMenu";
import DySignOutButton from "components/DySignOutButton";
import currentUser from "commons/currentUser";
import styles from "./styles";

function DyAppBar(props) {
  const { classes, className, position } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <AppBar
      position={position}
      color="default"
      className={classNames(classes.appbar, className)}
    >
      <Container maxWidth="xl">
        <Toolbar className={classes.toolbar} variant="dense">
          <a href={Routes.root_path()}>
            <img
              src={Logo}
              className={classes.logo}
              alt={I18n.t("components.app_bar.alt.dynaminds_logo")}
            />
          </a>
          <Hidden smDown>
            <nav>
              <Button
                component="a"
                // size="small"
                href={Routes.root_path()}
              >
                {I18n.t("components.app_bar.navigations.home")}
              </Button>
              <Button
                component="a"
                // size="small"
                href={Routes.accommodations_path()}
              >
                {I18n.t("components.app_bar.navigations.listings")}
              </Button>
              <Button
                component="a"
                // size="small"
                href={Routes.testimonials_path()}
              >
                {I18n.t("components.app_bar.navigations.testimonials")}
              </Button>
              <Button
                component="a"
                // size="small"
                href={FB_GROUP_URL}
                target="_blank"
                rel="noopener"
              >
                {I18n.t("components.app_bar.navigations.fb_group")}
              </Button>
            </nav>
          </Hidden>
          <Hidden mdUp>
            <DyAppMenu />
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

DyAppBar.defaultProps = {
  className: undefined,
  position: "static"
};
DyAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  position: PropTypes.oneOf([
    "fixed",
    "absolute",
    "sticky",
    "static",
    "relative"
  ])
};

export default withStyles(styles)(DyAppBar);
