import React from "react";
// App
import DyAppBar from "components/DyAppBar";
import DyHighlight from "components/DyHighlight";
import DyHighlight1 from "components/DyHighlight1";
import DyAppLayout from "components/DyAppLayout";
import DyFooter from "components/DyFooter";
import DyTestimonialSlider from "components/DyTestimonialSlider";
import DyLandingCollections from "components/DyLandingCollections";
// import DyHousingSearchPromotion from "components/DyHousingSearchPromotion";
import DyLandingService from "components/DyLandingServices";
import DyApolloProvider from "components/DyApolloProvider";
import withNotistack from "hocs/withNotistack";
import flash from "commons/flash";
import Paper from "@material-ui/core/Paper";

function DyLanding(props) {
  flash.handleBy(props.enqueueSnackbar);
  return (
    <DyApolloProvider>
      <DyAppLayout>
        <DyAppBar />
        <DyHighlight1 />
        <Paper>
          <DyLandingService />
          <DyLandingCollections />
          <DyTestimonialSlider testimonials={props.testimonials} />
          {/* <DyHousingSearchPromotion /> */}
          <DyHighlight />
        </Paper>
        <DyFooter />
      </DyAppLayout>
    </DyApolloProvider>
  );
}

export default withNotistack(DyLanding);
