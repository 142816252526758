import "./translations";
import React, { PureComponent } from "react";
import Proptypes from "prop-types";
// Material UI
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
// App
import { FB_GROUP_URL } from "commons/initializer";
import GroupBg from "images/bg_2";
import styles from "./styles";

function HighLightHeader(props) {
  return (
    <Typography
      component="h1"
      variant="h3"
      color="inherit"
      className={props.className}
    >
      {props.children}
    </Typography>
  );
}

function HighLightSubHeader(props) {
  return (
    <Typography
      variant="body2"
      color="inherit"
      component="h2"
      className={props.className}
      gutterBottom
    >
      {props.children}
    </Typography>
  );
}

function HighLightContent(props) {
  return (
    <Typography
      component="p"
      color="inherit"
      variant="h5"
      className={props.className}
      gutterBottom
    >
      {props.children}
    </Typography>
  );
}

function MainNumber(props) {
  return (
    <Typography component="p" color="secondary" variant="h4" align="center">
      {props.children}
    </Typography>
  );
}

function MainNumberCaption(props) {
  return (
    <Typography component="p" align="center" gutterBottom color="textSecondary">
      {props.children}
    </Typography>
  );
}

class DyHighlight extends PureComponent {
  render() {
    const { classes, children } = this.props;
    return (
      <section className={classes.highlight}>
        <Container maxWidth="xl" className={classes.highlightInner}>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={12} md={6} className={classes.highlightBrief}>
              <HighLightHeader>{I18n.t("components.highlight.header")}</HighLightHeader>
              <HighLightSubHeader className={classes.highlightSubHeader}>
                {I18n.t("components.highlight.subheader")}
              </HighLightSubHeader>
              <HighLightContent>
                &#10004; {I18n.t("components.highlight.meaning_1")}
              </HighLightContent>
              <HighLightContent>
                &#10004; {I18n.t("components.highlight.meaning_2")}
              </HighLightContent>
              <HighLightContent>
                &#10004; {I18n.t("components.highlight.meaning_3")}
              </HighLightContent>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardActionArea
                  component="a"
                  href={FB_GROUP_URL}
                  target="_blank"
                >
                  <CardMedia
                    component="img"
                    alt={I18n.t("components.highlight.group_img_alt")}
                    className={classes.cardMedia}
                    height="140"
                    image={GroupBg}
                    title={I18n.t("components.highlight.group_name")}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="p"
                      align="center"
                      paragraph
                    >
                      {I18n.t("components.highlight.group_name")}
                    </Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        <MainNumber>16000</MainNumber>
                        <MainNumberCaption>{I18n.t("components.highlight.members")}</MainNumberCaption>
                      </Grid>
                      <Grid item xs={6}>
                        <MainNumber>300</MainNumber>
                        <MainNumberCaption>{I18n.t("components.highlight.posts_per_month")}</MainNumberCaption>
                      </Grid>
                      <Grid item xs={6}>
                        <MainNumber>6000</MainNumber>
                        <MainNumberCaption>
                          {I18n.t("components.highlight.cr_per_month")[0]}
                          <br />
                          {I18n.t("components.highlight.cr_per_month")[1]}
                        </MainNumberCaption>
                      </Grid>
                      <Grid item xs={6}>
                        <MainNumber>90%</MainNumber>
                        <MainNumberCaption>
                          {I18n.t("components.highlight.posts_are_demands")[0]}
                          <br />
                          {I18n.t("components.highlight.posts_are_demands")[1]}
                        </MainNumberCaption>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    component="a"
                    color="primary"
                    fullWidth
                    variant="contained"
                    href={FB_GROUP_URL}
                    target="_blank"
                  >
                    {I18n.t("components.highlight.see_us_in_action")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </section>
    );
  }
}

DyHighlight.Proptypes = {
  classes: Proptypes.object.isRequired
};

export default withStyles(styles)(DyHighlight);
